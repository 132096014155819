import React from "react";
import "../styles/LiveTrades.css";

const PopupPositions = ({
  showPopup,
  selectedPosition,
  exitQuantity,
  setExitQuantity,
  price,
  setPrice,
  transaction_type, // Pass transaction_type as a prop
  setTransaction_type, // Pass a setter function for transaction_type
  confirmExit,
  closeExitPopup,
}) => {
  if (!showPopup || !selectedPosition) return null;

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <h3>Exit Position</h3>
        <p>Instrument token: {selectedPosition.instrumentToken}</p>
        <p>Net Traded Quantity: {selectedPosition.netTrdQtyLot}</p>
        <div>
          <label htmlFor="quantity"><strong>Exit Quantity (Lots):</strong></label>
          <input
            type="number"
            id="quantity"
            min="1"
            value={exitQuantity}
            onChange={(e) => {
              setExitQuantity(parseInt(e.target.value));
            }}
          />
        </div>
        <div style={{ paddingTop: "10px" }}>
          <label htmlFor="price"><strong>Price:</strong></label>
          <input
            type="number"
            id="price"
            min="0"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
          />
        </div>
        <div style={{ paddingTop: "10px" }}>
          <strong>Transaction Type:</strong>
          <label>
            <input
              type="radio"
              name="transaction_type"
              value="BUY"
              checked={transaction_type === "BUY"}
              onChange={(e) => setTransaction_type(e.target.value)}
            />
            BUY
          </label>
          <label style={{ marginLeft: "15px" }}>
            <input
              type="radio"
              name="transaction_type"
              value="SELL"
              checked={transaction_type === "SELL"}
              onChange={(e) => setTransaction_type(e.target.value)}
            />
            SELL
          </label>
        </div>
        <div className="tabs">
          <button onClick={confirmExit}>Confirm Exit</button>
          <button onClick={closeExitPopup}>Cancel</button>
        </div>
      </div>
    </div>
  );
};

export default PopupPositions;