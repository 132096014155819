import React, { useState } from 'react';
import api from '../utils/axiosConfig';
import '../styles/Users.css';
import { useSelector, useDispatch } from "react-redux";
import { setUsers } from "../redux/modelsSlice";

const Users = () => {
  const users = useSelector((state) => state.models.users);
  const dispatch = useDispatch();
  const [user, setUser] = useState(null);
  const [allUsers, setAllUsers] = useState([]);
  const [error, setError] = useState('');

  const fetchUser = async () => {
    try {
      const response = await api.post('/get_user', { users });
      if (response.status === 500) {
        setError('Error featching user');
      } else {
        setUser(JSON.stringify(eval('(' + response.data.login_info + ')'), null, 2));
        // setUser(JSON.stringify(JSON.parse(response.data.login_info), null, 2));
        setError('');
      }
    } catch (err) {
      setUser(null);
      setError(JSON.stringify(err));
    }
  };
  const updateUser = async () => {
    try {
      const confirmUpdate = window.confirm("Are you sure to Update user?");
      if (confirmUpdate) {
        const login_info = JSON.parse(user);
        const response = await api.post('/update_user', { users, login_info });
        if (response.status === 500) {
          setError('Error updating user');
        } else { setError(''); }
      }
    } catch (err) {
      setUser(null);
      setError(JSON.stringify(err));
    }
  };
  const setnewUser = async () => {
    try {
      const confirmSet = window.confirm("Are you sure to Set User?");
      if (confirmSet) {
        const login_info = JSON.parse(user);
        const response = await api.post('/create_user', { users, login_info });
        if (response.status === 500) {
          setError('Error seting user');
        } else { setError(''); }
      }
    } catch (err) {
      setUser(null);
      setError(JSON.stringify(err));
    }
  };
  const deleteUser = async () => {
    try {
      const confirmDelete = window.confirm("Are you sure to delete?");
      if (confirmDelete) {
        const response = await api.post('/delete_user', { users });
        if (response.status === 500) {
          setError('Error deleting user');
        } else { setError(''); }
      }
    }
    catch (err) {
      setUser(null);
      setError(JSON.stringify(err));
    }
  };
  const fetchAllUsers = async () => {
    try {
      const response = await api.get('/get_all_users');
      setAllUsers(response.data);
      if (response.status === 500) {
        setError('Error fetching all users');
      } else { setError(''); }
    } catch (err) {
      setAllUsers([]);
      setError(JSON.stringify(err));
    }
  };


  return (
    <div className="users-container">
      <h2>Users</h2>

      <div>
        <input
          type="text"
          placeholder="Enter user"
          value={users}
          onChange={(e) => dispatch(setUsers(e.target.value))}
        />

        {user && (
          <div>
            <h3>User logininfo (JSON)</h3>
            <textarea
              onChange={(e) => setUser(e.target.value)}
              value={user}
              rows="10"
              cols="50"
            />
          </div>
        )}

        <div className="button-section">
          <button onClick={fetchUser}>Get User</button>
          <button onClick={updateUser}>Update User</button>
          <button onClick={setnewUser}>Set New User</button>
          <button onClick={deleteUser}>Delete User</button>
        </div>
        <div className="button-section">
          <button onClick={fetchAllUsers}>Get All Users</button>
        </div>

        {error && <p className="error-message">{error}</p>}

        {allUsers.length > 0 && (
          <div>
            <h3>All Users (JSON)</h3>
            <textarea
              readOnly
              value={JSON.stringify(allUsers, null, 2)}
              rows="100"
              cols="200"
            />
          </div>
        )}

      </div>
    </div>
  );
};

export default Users;