import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import LoginForm from './components/LoginForm';
import Users from './components/Users';
import Navbar from './components/Navbar';
import Models from "./components/Models";
import HistoricalTrades from './components/HistoricalTrades';
import LiveTrades from "./components/LiveTrades";
import TickerData from "./components/TickerData";
import Backtest from "./components/Backtest";
import Logs from "./components/logs";
import './styles/global.css';
const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(!!localStorage.getItem('token'));

  const handleLogin = () => {
    setIsAuthenticated(true);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    setIsAuthenticated(false);
  };

  return (
    <Router>
      {isAuthenticated ? (
        <>
          <Navbar onLogout={handleLogout} />
          <Routes>
            <Route path="/" element={<Navigate to="/users" replace />} />
            <Route path="/users" element={<Users />} />
            <Route path="/models" element={<Models />} />
            <Route path="/historicalTrades" element={<HistoricalTrades />} />
            <Route path="/liveTrades" element={<LiveTrades />} />
            <Route path="/backtest" element={<Backtest />} />
            <Route path="/tickerData" element={<TickerData />} />
            <Route path="/logs" element={<Logs />} />
          </Routes>
        </>
      ) : (
        <LoginForm onLogin={handleLogin} />
      )}
    </Router>
  );
};

export default App;