import React, { useState, useEffect } from "react";
import api from "../utils/axiosConfig";
import "../styles/LiveTrades.css";
import PopupPositions from "./PopupPositions";
import { useSelector, useDispatch } from "react-redux";
import { setUsers, setModelName } from "../redux/modelsSlice";

const LiveTrades = () => {
  const { users, model_name } = useSelector((state) => state.models);
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("positions"); // Active tab state
  const [positions, setPositions] = useState({});
  const [tablePositions, setTablePositions] = useState([]);
  const [orders, setOrders] = useState({});
  const [tableOrders, setTableOrders] = useState([]);
  const [currentPositions, setCurrentPositions] = useState({});
  const [showPopup, setShowPopup] = useState(false);
  const [selectedPosition, setSelectedPosition] = useState(null);
  const [exitQuantity, setExitQuantity] = useState(0);
  const [price, setPrice] = useState(0.0);
  const [transaction_type, setTransaction_type] = useState("BUY");
  const [error, setError] = useState("");
  const [currentUsers, setCurrentUsers] = useState([]);

  // Fetch current users for dropdown
  const fetchCurrentUsers = async () => {
    try {
      const response = await api.post("/get_models", { users: "", model_name: "" });
      if (response.status === 500) {
        setError("Error fetching current users");
      } else {
        const uniqueUsers = [...new Set(response.data.map((item) => item.users))];
        setCurrentUsers(uniqueUsers);
        setError("");
      }
    } catch (err) {
      setCurrentUsers([]);
      setError(JSON.stringify(err));
    }
  };

  useEffect(() => {
    fetchCurrentUsers();
  }, []);

  // Fetch current positions
  const fetchCurrentPositions = async () => {
    try {
      const response = await api.post("/current_pos", { users, model_name });
      if (response.status === 200) {
        setCurrentPositions(response.data);
        setError("");
      } else {
        setCurrentPositions({});
        setError("No current positions found for the provided user.");
      }
    } catch (err) {
      setCurrentPositions({});
      setError(JSON.stringify(err));
    }
  };

  // Fetch positions
  const fetchPositions = async () => {
    try {
      const response = await api.post("/pos", { users });
      if (response.status === 200) {
        const data = response.data;
        setTablePositions(
          Object.keys(data.instrumentToken).map((key) => {
            return Object.fromEntries(
              Object.entries(data).map(([field, values]) => [field, values[key]])
            );
          })
        );
        setPositions(data);
        setError("");
      } else {
        setPositions({});
        setError("No positions found for the provided user.");
      }
    } catch (err) {
      setPositions({});
      setError(JSON.stringify(err));
    }
  };

  const openExitPopup = (position) => {
    setSelectedPosition(position);
    setExitQuantity(position.netTrdQtyLot || 0);
    setShowPopup(true);
  };

  const closeExitPopup = () => {
    setShowPopup(false);
    setSelectedPosition(null);
    setExitQuantity(0);
  };

  const confirmExit = async () => {
    try {
      const response = await api.post("/place_order", {
        users,
        transaction_type,
        price_: price,
        quantity: exitQuantity,
        token: selectedPosition.instrumentToken,
      });
      if (response.status === 200) {
        alert("Position exited successfully!");
        fetchPositions(); // Refresh positions
      } else {
        alert("Failed to exit the position.");
      }
    } catch (err) {
      alert("An error occurred while exiting the position.");
    }
    closeExitPopup();
  };

  // Fetch orders
  const fetchOrders = async () => {
    try {
      const response = await api.post("/ord", { users });
      if (response.status === 200) {
        const data = response.data;
        setTableOrders(
          Object.keys(data.orderId).map((key) => {
            return Object.fromEntries(
              Object.entries(data).map(([field, values]) => [field, values[key]])
            );
          })
        );
        setOrders(data);
        setError("");
      } else {
        setOrders({});
        setError("No orders found for the provided user.");
      }
    } catch (err) {
      setOrders({});
      setError(JSON.stringify(err));
    }
  };

  const handleCancelOrder = async (order) => {
    const confirmCancel = window.confirm("Are you sure you want to cancel this order?");
    if (confirmCancel) {
      try {
        const response = await api.post("/cancel_order", {
          users,
          order_id: order.orderId, // Example field to identify the position
        });

        if (response.status === 200) {
          alert("Order canceled successfully!");
          fetchOrders();
        } else {
          alert("Failed to cancel the order.");
        }
      } catch (err) {
        alert("An error occurred while canceling the order.");
      }
    }
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    setError("");
    if (tab === "currentPositions") {
      fetchCurrentPositions();
    } else if (tab === "positions") {
      fetchPositions();
    } else if (tab === "orders") {
      fetchOrders();
    }
  };

  return (
    <div className="trade-details-container">
      <h2>Live Trades</h2>

      {/* Input Section */}
      <div className="input-section">
        <select
          value={users}
          onChange={(e) => dispatch(setUsers(e.target.value))}
        >
          {currentUsers.map((user, index) => (
            <option key={index} value={user}>
              {user}
            </option>
          ))}
        </select>
        <input
          type="text"
          placeholder="Enter Model Name"
          value={model_name}
          onChange={(e) => dispatch(setModelName(e.target.value))}
        />
      </div>

      {/* Tabs */}
      <div className="tabs">
        <button
          className={activeTab === "currentPositions" ? "active-tab" : ""}
          onClick={() => handleTabClick("currentPositions")}
        >
          Current Positions
        </button>
        <button
          className={activeTab === "positions" ? "active-tab" : ""}
          onClick={() => handleTabClick("positions")}
        >
          Positions
        </button>
        <button
          className={activeTab === "orders" ? "active-tab" : ""}
          onClick={() => handleTabClick("orders")}
        >
          Orders
        </button>
      </div>

      {/* Error Message */}
      {error && <p className="error-message">{error}</p>}

      {/* Current Positions Tab */}
      {activeTab === "currentPositions" && (
        <div className="data-display">
          <h3>Current Positions</h3>
          {Object.entries(currentPositions).map(([key, value]) => (
            <div key={key} className="result-entry">
              <div>
                <strong>{key}</strong>
              </div>
              <div>
                <span>Entry:</span>
                <pre>
                  {typeof value.entry === "string"
                    ? value.entry.replace(/,/g, ",\n")
                    : JSON.stringify(value.entry || {}, null, 2)}
                </pre>
              </div>
              <div>
                <span>Partial Exits:</span>
                <pre>
                  {typeof value.partial_exits === "string"
                    ? value.partial_exits.replace(/,/g, ",\n")
                    : JSON.stringify(value.partial_exits || {}, null, 2)}
                </pre>
              </div>
            </div>
          ))}
        </div>
      )}

      {/* Positions Tab */}
      {activeTab === "positions" && tablePositions.length === 0 && (
        <div><p>No Positions</p></div>
      )}
      {activeTab === "positions" && tablePositions.length > 0 && (
        <div className="data-display">
          <h3>Positions</h3>
          <table className="data-table">
            <thead>
              <tr>
                {Object.keys(positions).map((key) => (
                  <th key={key}>{key}</th>
                ))}
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {tablePositions.map((row, index) => (
                <tr key={index}>
                  {Object.values(row).map((value, idx) => (
                    <td key={idx}>{value}</td>
                  ))}
                  <td>
                    <button onClick={() => openExitPopup(row)}>Exit</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      <PopupPositions
        showPopup={showPopup}
        selectedPosition={selectedPosition}
        exitQuantity={exitQuantity}
        setExitQuantity={setExitQuantity}
        price={price}
        setPrice={setPrice}
        transaction_type={transaction_type}
        setTransaction_type={setTransaction_type}
        confirmExit={confirmExit}
        closeExitPopup={closeExitPopup}
      />

      {/* Orders Tab */}
      {activeTab === "orders" && tableOrders.length === 0 && (
        <div><p>No Orders</p></div>
      )}
      {activeTab === "orders" && tableOrders.length > 0 && (
        <div className="data-display">
          <h3>Orders</h3>
          <table className="data-table">
            <thead>
              <tr>
                {Object.keys(orders).map((key) => (
                  <th key={key}>{key}</th>
                ))}
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {tableOrders.map((row, index) => (
                <tr key={index}>
                  {Object.values(row).map((value, idx) => (
                    <td key={idx}>{value}</td>
                  ))}
                  <td>
                    <button onClick={() => handleCancelOrder(row)}>Cancel</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default LiveTrades;