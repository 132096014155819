// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 50vw;
  margin: 0 auto;
  background-color: var(--Pcontrast);
  border: 1px solid #ddd;
  border-radius: 8px;
  font-family: Arial, sans-serif;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 100px;
  gap: 10px
}

.form-container {

  gap: 30px
}

.login-container h2 {
  text-align: center;
  margin-bottom: 30px;
}`, "",{"version":3,"sources":["webpack://./src/styles/LoginForm.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,2BAA2B;EAC3B,mBAAmB;EACnB,WAAW;EACX,cAAc;EACd,kCAAkC;EAClC,sBAAsB;EACtB,kBAAkB;EAClB,8BAA8B;EAC9B,wCAAwC;EACxC,cAAc;EACd;AACF;;AAEA;;EAEE;AACF;;AAEA;EACE,kBAAkB;EAClB,mBAAmB;AACrB","sourcesContent":[".login-container {\r\n  display: flex;\r\n  flex-direction: column;\r\n  justify-content: flex-start;\r\n  align-items: center;\r\n  width: 50vw;\r\n  margin: 0 auto;\r\n  background-color: var(--Pcontrast);\r\n  border: 1px solid #ddd;\r\n  border-radius: 8px;\r\n  font-family: Arial, sans-serif;\r\n  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\r\n  padding: 100px;\r\n  gap: 10px\r\n}\r\n\r\n.form-container {\r\n\r\n  gap: 30px\r\n}\r\n\r\n.login-container h2 {\r\n  text-align: center;\r\n  margin-bottom: 30px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
