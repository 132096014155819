import React, { useState } from "react";
import api from "../utils/axiosConfig";
import "../styles/TickerData.css";

const TickerData = () => {
  const [stoc_ind, setStoc_ind] = useState("");
  const [error, setError] = useState("");
  const [downloadStatus, setDownloadStatus] = useState("");

  const fetchTickerData = async () => {
    try {
      setDownloadStatus("Downloading...")
      const response = await api.post("/get_candle_data", { stoc_ind }, {
        responseType: "blob",
      });
      if (response.status === 200) {
        setError("");
        const blob = new Blob([response.data], { type: response.headers['content-type'] });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${stoc_ind}.xlsx`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setDownloadStatus("Downloaded")
      } else {
        setError("No data found for the provided parameters.");
      }
    } catch (err) {
      setError(JSON.stringify(err));
    }
  };

  return (
    <div className="ticker-container">
      <h2>Fetch Ticker Data</h2>
      <div className="input-section" >
        <input
          type="textarea"
          placeholder="Enter Ticker Name"
          value={stoc_ind}
          onChange={(e) => setStoc_ind(e.target.value)}
        />
        <button onClick={fetchTickerData}>Get Data</button>
      </div>
      {error && <p className="error-message">{error}</p>}
      <div  >
        <p> Download Status: {downloadStatus}</p>
      </div>

    </div>
  );
};

export default TickerData;