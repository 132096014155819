import React, { useState } from "react";
import api from "../utils/axiosConfig";
import "../styles/HistoricalTrades.css";
import { useSelector, useDispatch } from "react-redux";
import { setUsers, setModelName } from "../redux/modelsSlice";

const HistoricalTrades = () => {
  const { users, model_name } = useSelector((state) => state.models);
  const dispatch = useDispatch();
  const [historicalTrades, setHistoricalTrades] = useState([]);
  const [error, setError] = useState("");

  const fetchTrades = async () => {
    try {
      const response = await api.post("/get_trades", { users, model_name });

      if (response.status === 200) {
        const sortedTrades = response.data.sort((a, b) =>
          new Date(b.entry_datetime) - new Date(a.entry_datetime)
        );

        setHistoricalTrades(sortedTrades);
        setError("");
      } else {
        setError("No trades found for the provided users and model name.");
        setHistoricalTrades([]);
      }
    } catch (err) {
      setError(JSON.stringify(err));
      setHistoricalTrades([]);
    }
  };

  return (
    <div className="result-container">
      <h2>Historical Trades</h2>

      {/* Input Section */}
      <div className="input-section">
        <input
          type="text"
          placeholder="Enter User"
          value={users}
          onChange={(e) => dispatch(setUsers(e.target.value))}
        />
        <input
          type="text"
          placeholder="Enter Model Name"
          value={model_name}
          onChange={(e) => dispatch(setModelName(e.target.value))}
        />
        <button onClick={fetchTrades}>Get Trades</button>
      </div>

      {/* Error Message */}
      {error && <p className="error-message">{error}</p>}

      {/* Display Historical Trades */}
      {historicalTrades.length === 0 && (
        <div><p>No Historical Trades</p></div>
      )}
      {historicalTrades.length > 0 && (
        <div className="results-display">
          {historicalTrades.map((trade, index) => (
            <div key={index} className="result-entry">
              <div className="result-field" style={{ display: "flex", gap: "1rem" }}>
                <span>Trade {index + 1}</span>
                <span>{trade.users}</span>
                <span>{trade.model_name}</span>
                <span>{trade.symbol}</span>
              </div>
              <div className="result-field">
                <strong>Entry Datetime:</strong> <span>{new Date(trade.entry_datetime).toLocaleString()}</span>
              </div>
              <div className="result-field">
                <strong>Entry:</strong>
                <pre> {typeof trade.entry === "string" ? trade.entry.replace(/,/g, ",\n") : JSON.stringify(trade.entry || {}, null, 2)}</pre>
              </div>
              <div className="result-field">
                <strong>Partial Exits:</strong>
                <pre>
                  {typeof trade.partial_exits === "string" ? trade.partial_exits.replace(/,/g, ",\n") : JSON.stringify(trade.partial_exits || {}, null, 2)}</pre>

              </div>
              <div className="result-field">
                <strong>Exit Datetime:</strong> <span>{new Date(trade.exit_datetime).toLocaleString()}</span>
              </div>
              <div className="result-field">
                <strong>Exit:</strong>
                <pre>{JSON.stringify(trade.exit, null, 2)}</pre>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default HistoricalTrades;