import React, { useState } from "react";
import api from "../utils/axiosConfig";
import "../styles/Models.css";
import { useSelector, useDispatch } from "react-redux";
import { setUsers, setModelName } from "../redux/modelsSlice";

const Models = () => {
  const { users, model_name } = useSelector((state) => state.models);
  const dispatch = useDispatch();
  const [modelDetails, setModelDetails] = useState("");
  const [enable, setEnable] = useState(false);
  const [live, setLive] = useState(false);
  const [models, setModels] = useState([]);
  const [error, setError] = useState("");

  // Get models
  const fetchModel = async () => {
    try {
      const response = await api.post("/get_models", { users, model_name });
      if (model_name === "") {
        if (response.status === 500) {
          setError('Error featching models');
        } else {
          setModels(response.data);
          setError('');
        }
      } else {
        setModelDetails(JSON.stringify(JSON.parse(response.data.model_detail), null, 2));
        setLive(response.data.live)
        setEnable(response.data.enable)
        setError('');
      }
    } catch (err) {
      setModels([]);
      setError(JSON.stringify(err));
    }
  };

  // Add a new model
  const addModel = async () => {
    const confirmAdd = window.confirm("Are you sure to Add?");
    if (confirmAdd) {
      try {
        const model_detail = JSON.parse(modelDetails)
        const response = await api.post("/create_model", { users, model_name, model_detail, enable, live });
        if (response.status === 500) {
          setError('Error adding model');
        } else {
          setError('');
        }
      } catch (err) {
        setError(JSON.stringify(err));
      }
    }
  };

  // Update an existing model
  const updateModel = async () => {
    const confirmUpdate = window.confirm("Are you sure to Update?");
    if (confirmUpdate) {
      try {
        const model_detail = JSON.parse(modelDetails)
        const response = await api.post("/update_model", { users, model_name, model_detail, enable, live });
        if (response.status === 500) {
          setError('Error updating model');
        } else {
          setError('');
        }
      } catch (err) {
        setError(JSON.stringify(err));
      }
    }
  };

  // Delete a model
  const deleteModel = async () => {
    const confirmDelete = window.confirm("Are you sure to delete?");
    if (confirmDelete) {
      try {
        const response = await api.post("/delete_model", { users, model_name });
        if (response.status === 500) {
          setError('Error deleting model');
        } else {
          setError('');
        }
      } catch (err) {
        setError(JSON.stringify(err));
      }
    }
  };

  return (
    <div className="trades-container">
      <h2>Models</h2>

      {/* Input Fields */}
      <div className="input-section">
        <input
          type="text"
          placeholder="Enter User"
          value={users}
          onChange={(e) => dispatch(setUsers(e.target.value))}
        />
        <input
          type="text"
          placeholder="Enter Model Name"
          value={model_name}
          onChange={(e) => dispatch(setModelName(e.target.value))}
        />
        <textarea
          placeholder="Enter Model Details"
          value={modelDetails}
          onChange={(e) => setModelDetails(e.target.value)}
          rows="15"
        />
        <div>
          <label>
            <input
              type="checkbox"
              checked={enable}
              onChange={(e) => setEnable(e.target.checked)}
            />
            Enable
          </label>
          <label>
            <input
              type="checkbox"
              checked={live}
              onChange={(e) => setLive(e.target.checked)}
            />
            Live
          </label>
        </div>
      </div>

      {/* Buttons for Operations */}
      <div className="button-section">
        <button onClick={fetchModel}>Get Models</button>
        <button onClick={addModel}>Add Models</button>
        <button onClick={updateModel}>Update Models</button>
        <button onClick={deleteModel}>Delete Models</button>
      </div>

      {/* Display Errors */}
      {error && <p style={{ color: "red" }}>{error}</p>}

      {/* Display Models */}
      {models.length > 0 && (
        <div className="trades-display">
          <h3>Model Details</h3>
          <textarea
            readOnly
            value={JSON.stringify(models, null, 2)} // Display models as JSON
            rows="100"
            cols="100"
          />
        </div>
      )}

    </div>
  );
};

export default Models;