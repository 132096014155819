import { createSlice } from "@reduxjs/toolkit";

const modelsSlice = createSlice({
  name: "models",
  initialState: {
    users: "",
    model_name: "",
  },
  reducers: {
    setUsers: (state, action) => {
      state.users = action.payload;
    },
    setModelName: (state, action) => {
      state.model_name = action.payload;
    },
  },
});

export const { setUsers, setModelName } = modelsSlice.actions;

export default modelsSlice.reducer;