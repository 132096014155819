import React, { useState } from "react";
import api from '../utils/axiosConfig';
import '../styles/Backtest.css';
import { useSelector, useDispatch } from "react-redux";
import { setModelName } from "../redux/modelsSlice";

const Backtest = () => {
  const model_name = useSelector((state) => state.models.model_name);
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [date, setDate] = useState("2017-01-01");
  const [modelDetails, setModelDetails] = useState("");
  const [marketDataRecentNRows, setMarketDataRecentNRows] = useState(5000);
  const [generatedTaskId, setGeneratedTaskId] = useState("");
  const [task_id, setTask_id] = useState("");
  const [status, setStatus] = useState(null);
  const [error, setError] = useState("");
  const [downloadStatus, setDownloadStatus] = useState("");

  const submitBacktest = async (e) => {
    e.preventDefault();
    setError("");
    setGeneratedTaskId("");
    try {
      const model_detail = JSON.parse(modelDetails)
      model_detail['Email'] = email
      model_detail['modelName'] = model_name
      model_detail['date'] = date
      model_detail['market_data_recent_n_rows'] = marketDataRecentNRows
      const response = await api.post('/backtesting', model_detail);
      setGeneratedTaskId(response.data.task_id);
      setTask_id(response.data.task_id)
      setError(response.data.error);
    } catch (err) {
      setError(JSON.stringify(err));
    }
  };

  const handleFetchStatus = async (e) => {
    e.preventDefault();
    setError("");
    setStatus(null);
    try {
      const response = await api.post('/backtest_status', { task_id });
      setStatus(response.data);
      setDownloadStatus("");
      if (response.data.status === "SUCCESSES") {
        setDownloadStatus("Preparing download...");
        const responseFile = await api.post(
          "/results_file",
          {
            date: response.data.file_details.date,
            name: response.data.file_details.name,

          },
          {
            responseType: "blob", // Ensures the response is treated as a binary file
          }
        );

        if (responseFile.status === 200) {
          setError("");
          setDownloadStatus("Downloading...");

          const blob = new Blob([responseFile.data], { type: responseFile.headers["content-type"] });
          const url = window.URL.createObjectURL(blob);

          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${response.data.file_details.name}.xlsx`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          setDownloadStatus("Downloaded successfully");
        } else {
          setDownloadStatus("");
          setError("Error getting the file");
        }
      }
    } catch (err) {
      setError(JSON.stringify(err));

    }
  };

  return (
    <div className="container">
      <div className="card">
        <h2>Backtesting</h2>
        <form >
          <label htmlFor="email">Email:</label>
          <input
            type="text"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter Email"
          />
          <label htmlFor="model_name">Model Name:</label>
          <input
            type="text"
            id="model_name"
            value={model_name}
            onChange={(e) => dispatch(setModelName(e.target.value))}
            placeholder="Enter Model Name"
          />
          <label htmlFor="date">Date:</label>
          <input
            type="text"
            id="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
            placeholder="Enter Date"
          />
          <label htmlFor="modelDetails">Model Details:</label>
          <textarea style={{ height: "50vh" }}
            id="modelDetails"
            value={modelDetails}
            onChange={(e) => setModelDetails(e.target.value)}
            placeholder="Enter Model Details"
          />
          <label htmlFor="marketDataRecentNRows">Market Data Recent N Rows:</label>
          <input
            type="text"
            id="marketDataRecentNRows"
            value={marketDataRecentNRows}
            onChange={(e) => setMarketDataRecentNRows(e.target.value)}
            placeholder="Enter Market Data Recent N Rows"
          />
          <div className="button-section">
            <button onClick={submitBacktest}>Submit for Backtest</button>
          </div>
        </form>
      </div>

      <div className="card">
        <h2>Backtest Status</h2>
        <form onSubmit={handleFetchStatus}>
          <label htmlFor="task_id">Task ID:</label>
          <input
            type="text"
            id="task_id"
            value={task_id}
            onChange={(e) => setTask_id(e.target.value)}
            placeholder="Enter Task ID"
          />
          <button type="submit">Fetch Status</button>
        </form>

        {status && (
          <div className="result">
            <h3>Backtest Status:</h3>
            <pre>{JSON.stringify(status, null, 2)}</pre>
            {status.status === "SUCCESSES" && (
              <p> Download Status: {downloadStatus}</p>
            )}

          </div>
        )}
        {error && <p className="error-message">{error}</p>}
        {generatedTaskId && (
          <p className="success-message">Generated Task ID: {generatedTaskId}</p>
        )}
      </div>
    </div>
  );
};

export default Backtest;