import React, { useState } from "react";
import api from "../utils/axiosConfig";
import "../styles/Models.css";
import { useSelector, useDispatch } from "react-redux";
import { setUsers, setModelName } from "../redux/modelsSlice";

const Logs = () => {
    const { users, model_name } = useSelector((state) => state.models);
    const dispatch = useDispatch();
    const getCurrentDate = () => {
        const today = new Date();
        const yyyy = today.getFullYear();
        const mm = String(today.getMonth() + 1).padStart(2, '0');
        const dd = String(today.getDate()).padStart(2, '0');
        return `${yyyy}-${mm}-${dd}`;
    };

    const [date, setDate] = useState(getCurrentDate());
    const [error, setError] = useState("");
    const [downloadStatus, setDownloadStatus] = useState("");

    const fetchLogFile = async (e) => {
        try {
            setDownloadStatus("Downloading...")
            const response = await api.post('/log_file', { users, model_name, date }, {
                responseType: "blob",
            });
            if (response.status === 200) {
                setError("");
                const blob = new Blob([response.data], { type: response.headers['content-type'] });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", `${users}${model_name}_${date}.log`);//   sssssssssset file name
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                setDownloadStatus("Downloaded")
            } else {
                setError("No data found for the provided parameters.");
            }
        } catch (err) {
            setError(JSON.stringify(err));
        }
    };

    return (
        <div className="trades-container">
            <h2>Fetch Log File</h2>
            <div className="input-section" >
                <input
                    type="text"
                    placeholder="Enter User"
                    value={users}
                    onChange={(e) => dispatch(setUsers(e.target.value))}
                />

                <input
                    type="text"
                    placeholder="Enter Model Name"
                    value={model_name}
                    onChange={(e) => dispatch(setModelName(e.target.value))}
                />
                <input
                    type="date"
                    value={date}
                    onChange={(e) => {
                        const selectedDate = new Date(e.target.value);
                        const yyyy = selectedDate.getFullYear();
                        const mm = String(selectedDate.getMonth() + 1).padStart(2, '0');
                        const dd = String(selectedDate.getDate()).padStart(2, '0');
                        setDate(`${yyyy}-${mm}-${dd}`);
                    }}
                />
                <div className="button-section">
                    <button onClick={fetchLogFile}>Download Log File</button>
                </div>
            </div>
            {error && <p className="error-message">{error}</p>}
            <div  >
                <p> Download Status: {downloadStatus}</p>
            </div>

        </div>
    );
};

export default Logs;